import { takeEvery, put, select } from 'redux-saga/effects';
import { UPDATE_QUANTITY } from '../../apps/actions/actionTypes';
import { MEDIA_GALLERY_IMAGES_READY,RE_CALCULATE_PRICE, SET_CONFIG_SETTINGS } from '../../actions/index';
import { safeSaga } from '../utils';
import getMediaGalleryImages from '../../selectors/getMediaGalleryImages';
import { getSelectedBoardPriceSelector } from '../../selectors/getSelectedBoardPriceSelector';
import { getCatalog } from '../../actions';
import getThdStore from '../../utils/getThdStore';
import getConfiguratorSettings from '../../selectors/configuratorSettings';
import omsId from '../../../../utils/getOmsIdFromUrl';

const processGalleryImagesAndVideos = (mediaImages) => {
  const result = mediaImages.map((x) => {
    const id = x.key;
    const url =
      x.primaryLink.replace("hei=1000", "hei=<SIZE>") ||
      x.primaryLink.replace("hei=100", "hei=<SIZE>");
      const videoStill = x.mediaType === 'VIDEO' ? x.thumbnail : null;
      const sizes = ["100", "145", "600", "1000"];

    return {
      id,
      sizes,
      type: x.mediaType,
      url: url,
      source: "CONFIGURABLE_S3",
      videoStill
    };
  });

  return result
}

function* updateHarmonyComponents(action) {
  switch (action.type) {
    case SET_CONFIG_SETTINGS: {
      try {
        const thdStore = getThdStore();
        const configSettings = yield select(getConfiguratorSettings);
              yield put(getCatalog(
          configSettings.decksApiUrl,
          thdStore.storeNumber, configSettings.productId, configSettings.externalId
        ));
      } catch (err) {
        console.log(err);
      }
      break;
    }
    case MEDIA_GALLERY_IMAGES_READY: {

      const { mediaImages } = yield select(getMediaGalleryImages);
      if (!mediaImages || mediaImages.length == 0) {
        break;
      }
      const result = processGalleryImagesAndVideos(mediaImages)

      const galleryPayload = {
        externalMedia: [
          ...result
        ]
      };

      // update gallery
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_media_gallery_event", galleryPayload);

      // update sticky nav
      const stickyNavPayload = {
        configuredAssetUrl: mediaImages[0].thumbnail,
      };
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_sticky_nav_event", stickyNavPayload);
      break;
    }

    case RE_CALCULATE_PRICE:
      {
        try {
          const boardCost = yield select(getSelectedBoardPriceSelector);
          const propProductData = {
            configuratorPricing: {
              itemId: omsId,
              pricing: {
                alternate: {
                  bulk: {
                    value: null,
                    thresholdQuantity: null,
                  },
                  unit: {
                    unitsPerCase: null,
                    caseUnitOfMeasure: null,
                    unitsOriginalPrice: null,
                    value: null,
                  }
                },
                alternatePriceDisplay: false,
                mapAboveOriginalPrice: null,
                message: null,
                original: boardCost,
                preferredPriceFlag: null,
                promotion: {
                  dates: {
                    start: null,
                    end: null,
                  },
                  dollarOff: 0,
                  percentageOff: 0,
                  savingsCenter: null,
                  savingsCenterPromos: null,
                  specialBuySavings: null,
                  specialBuyDollarOff: null,
                  specialBuyPercentageOff: null,
                  type: "DISCOUNT",
                },
                specialBuy: null,
                unitOfMeasure: "each",
                value: boardCost,
              }
            }
          };
          LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_pricing_event', propProductData);
        } catch (err) {
          console.log(err);
        }
      }

  }


}


export function* deckingHarmonySaga() {

  yield takeEvery(
    [UPDATE_QUANTITY, MEDIA_GALLERY_IMAGES_READY, RE_CALCULATE_PRICE, SET_CONFIG_SETTINGS, 'GCC_CONFIGURATOR/LOAD_OPTIONS_SUCCESS'],
    safeSaga(updateHarmonyComponents),
  );
}

export default deckingHarmonySaga;
