import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { Option } from '@gcc/configurator';
import QuantityTemplate from './QuantityTemplate';

import SampleButton from './samples';

import getThdStore from '../../utils/getThdStore';
import { getSelectedEdgeSelector } from '../../selectors';
import { Utils } from '../../common';

import Estimator from '../estimator';
import ResurfacerCallout from './ResurfacerCallout';

import { Row, Column } from './SharedLayoutStyles';
import marketAvailabilitySelector from '../../selectors/marketAvailabilitySelector';
import getConfiguratorSettings from '../../selectors/configuratorSettings';
import HardwareAssemblyWrapper from '../../components/HardwareAssemblyWrapper/index';

import Portal from '../../../../components/Portal';
import DraftBanner from '../../../../components/DraftBanner';

const Layout = ({
    options, storeId, productId, isInMarket, isFascia, configs, details
}) => {
    if (isEmpty(options.allIds)) return null;

    const getOptionByTag = (name) => {
        const option = find(
            options,
            (o) => o.tags && o.tags.indexOf(name) !== -1,
        );
        if (option) {
            return option;
        }
        return null;
    };

    const optionsToDisplay = (OptionByTag) => {
        if (!OptionByTag) return null;
        const howManyChoices = OptionByTag.choices;
        if (howManyChoices.length > 1) return (<Option option={OptionByTag} />);
        return null;
    };

    const deckingDimensionOptions = optionsToDisplay(getOptionByTag('decking_dimensions'));
    const deckingColorOptions = optionsToDisplay(getOptionByTag('decking_color'));
    const deckingEdgeOptions = optionsToDisplay(getOptionByTag('decking_edge'));

    return (
        <div className="buybox" data-section="buybox">
          {
            configs?.isDraftProduct && (
                <Portal node={document.querySelector('.hfapp')}>
                  <DraftBanner details={details}/>
                </Portal>
              )
          }
            <Column>
                <Row>
                    {!isFascia && (
                        <>
                            <SampleButton
                                storeId={storeId}
                                productId={productId}
                                configs={configs}
                            />
                        </>
                    )}
                </Row>
                <ResurfacerCallout showingSupportedBrand />
            </Column>
            <div className="buybox__super-sku">
                {isInMarket && (
                    <div className="product_sku_Overlay_ListBoxes u__clearfix">
                        {
                            deckingDimensionOptions || deckingColorOptions || deckingEdgeOptions ? (
                                <h2 className="buybox__section-title">Choose Your Options</h2>) : null
                        }
                        {deckingDimensionOptions}
                        <div className="product_sku_Overlay_ListBoxes u__clearfix">
                            {deckingColorOptions}
                            {deckingEdgeOptions}
                        </div>
                    </div>
                )}
            </div>
            { isInMarket && <QuantityTemplate /> }
            { isInMarket && !isFascia && <Estimator /> }
            { isInMarket && <HardwareAssemblyWrapper /> }
        </div>
    );
};

Layout.propTypes = {
    options: PropTypes.object.isRequired,
    storeId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    isInMarket: PropTypes.bool.isRequired,
    isFascia: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const product = state.configurator.productInfo;
    return {
        options: state.configurator.options,
        storeId: Utils.getStoreIdFromStateOrCookie(state, getThdStore),
        productId: product.productId,
        selectedEdge: getSelectedEdgeSelector(state),
        isInMarket: marketAvailabilitySelector(state),
        configs: getConfiguratorSettings(state),
        details: {
          productName: product?.siteProductName || 'Draft'
        },
        isFascia: product?.productType === "Fascia Boards"
    };
};

export default connect(mapStateToProps)(Layout);
