import React from 'react';
import PropTypes from 'prop-types';
import { Configurator } from '@gcc/configurator';
import getConfiguratorSettings from '../../utils/getConfiguratorSettings';
import Layout from '../templates/Layout';
import injectedConfiguratorSettings from '../../../../config';
import useConfiguratorDataFromGraph from '../hooks/useConfiguratorDataFromGraph';
import getOmsIdFromUrl from '../../../../utils/getOmsIdFromUrl';
import isDraftProduct from "../../../../utils/isDraftProduct";
const omsIdFromUrl = getOmsIdFromUrl

const PIP = (props)=> {

  const config = { ...injectedConfiguratorSettings };  
  const { onLoadSuccess, updatePricing, partnerProductId, productDelivery, storeId, zipCode } = props;  
  const configuratorSettings = getConfiguratorSettings({ onLoadSuccess, config, updatePricing });

  useConfiguratorDataFromGraph({ productDelivery, partnerProductId: isDraftProduct ? omsIdFromUrl : partnerProductId, storeId, zipCode });

  return (
    <>
    <div className="buybox" data-section="buybox">
      <div className="buybox__super-sku">
        <Configurator settings={configuratorSettings}>
          <Layout />
        </Configurator>
      </div>
    </div>
  </>
  );
};



PIP.defaultProps = {
  bazaarVoiceRatings: {
    totalQuestions: 0,
    totalReviews: 0,
    totalAnswers: 0,
  },
};
PIP.propTypes = {
  bazaarVoiceRatings: PropTypes.object,
  storeSku: PropTypes.string.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  getBazaarVoiceRatings: PropTypes.func.isRequired,
};


export default PIP;